import React, { Component } from 'react'
import './Welcome.css';
import UmamiScriptComponent from './analytics/UmamiScriptComponent';

const img1 = require('../img/welcome-page-1.png');
const img2 = require('../img/welcome-page-2.png');

export default class Welcome extends Component {
    createConfetti() {
        const confettiContainer = document.getElementById("confettiContainer");
        const confettiCount = 100; // Number of confetti pieces

        const getRandomColor = () => {
            const colors = ["#FFC700", "#FF5733", "#33FF57", "#5733FF", "#33FFF7", "#F733FF"];
            return colors[Math.floor(Math.random() * colors.length)];
        }
    
        for (let i = 0; i < confettiCount; i++) {
            const confetti = document.createElement("div");
            confetti.classList.add("confetti");
    
            // Randomize confetti color
            confetti.style.backgroundColor = getRandomColor();
            
            // Random position and rotation
            confetti.style.left = `${Math.random() * 100}vw`;
            confetti.style.top = `${Math.random() * -100}vh`;
            confetti.style.transform = `rotate(${Math.random() * 360}deg)`;
    
            confettiContainer.appendChild(confetti);
    
            // Remove confetti after animation ends
            confetti.addEventListener('animationend', () => confetti.remove());
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.createConfetti();
        }, 500)
    }

    render() {
        return (
            <div className='welcome-container'>
                <div id="confettiContainer"></div>
                <div className='welcome-content-container'>
                    <h1 className='welcome-title'>Email Signature Generator installed successfully <span role="img" aria-label="confetis">🎉</span></h1>
                    <div className='step-container'>
                        <h2 className='step-content'>1. Pin the email signature extension for quick access</h2>
                        <img src={img1} alt='pin extension'className='img-container'/>
                    </div>
                    <div className='step-container'>
                        <h2 className='step-content'>2. Click the extension icon to open the email signature generator</h2>
                        <img src={img2} alt='click on an extension'className='img-container'/>
                    </div>
                </div>
                <UmamiScriptComponent/>
            </div>
        )
    }
}
