import React from 'react'

const SignatureDisplay = (props) => {
    const {content,social,images,style}=props.list;
    const logo=images[0];
    const profile=images[1]

    const mainStyle={
        borderCollapse:"separate",
        border: "1px solid",
        borderRadius:style[2].userInput+"px",
        background:style[0].userInput,
        color:style[1].userInput,
        fontSize:'14px',
        paddingTop:"6px",
        paddingBottom:"6px",
        paddingLeft:"15px",
        paddingRight:"60px"
    }

    const contentStyle={
        paddingTop:style[3].userInput+"px",
        marginLeft:"10px"
    }
    const logoStyle={
        width:style[4].userInput+"px",
        height:style[4].userInput+"px",
        borderRadius:style[5].userInput+"px",
        marginBottom:"10px"
    }
    const profileStyle={
        width:style[6].userInput+"px",
        height:style[6].userInput+"px",
        borderRadius:style[7].userInput+"px",
        marginLeft:"10px"
        
    }
    const socialStyle={
        width:style[8].userInput+"px",
        height:style[8].userInput+"px",
        borderRadius:style[9].userInput+"px",
        marginLeft:"1px",
        marginRight:"1px",
        backgroundColor: style[1].userInput
    };

    return(
        <div onClick={props.onClick} >
              <table style={mainStyle}>
                <tbody>
                  <tr>
                        <td>
                            {logo.userInput.length>0
                                ? <div style={{marginBottom:"4px", display: 'flex', justifyContent: 'center'}}>
                                    <img  alt="img-logo" style={logoStyle} src={logo.userInput}/>
                                </div>
                                : null                                       
                            }
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {
                                social.map((el, index)=>{
                                    if(el.userInput.length > 0) {
                                        return  (
                                            <div key={index}>
                                                <a target="_blank" rel="noopener noreferrer" href={el.userInput}>
                                                    <img
                                                        style={socialStyle}
                                                        alt="img-social"
                                                        src={el.icon}
                                                    />
                                                </a>
                                            </div>
                                        )
                                    }
                                    else return null;
                                })
                            }
                            </div>
                        </td>
                        <td>
                            <div style={{borderLeftColor:style[1].userInput,borderLeftStyle:'solid',marginLeft:"4px",paddingLeft:"12px"}} >
                                {profile.userInput.length>0
                                    ? <div>
                                        <img alt="img" style={profileStyle} src={profile.userInput}/>
                                    </div>
                                    : null
                                }
                
                                {content.filter(el=>el.length!==0).map((el,index)=>{
                                    if (el.id === 'Email') {
                                        return (
                                            <div style={contentStyle} key={index}>
                                                <a href={`mailto:${el.userInput}`}>{el.userInput}</a>
                                            </div>
                                        )
                                    }

                                    if (el.id === 'Website') {
                                        if (el.userInput.includes('http')) {
                                           return (
                                                <div style={contentStyle} key={index}>
                                                    <a href={`${el.userInput}`} target="_blank" rel="noopener noreferrer">{el.userInput}</a>
                                                </div>
                                           )
                                        } else {
                                            return (
                                                <div style={contentStyle} key={index}>
                                                    <a href={`https://${el.userInput}`} target="_blank" rel="noopener noreferrer">{el.userInput}</a>
                                                </div>
                                            )
                                        }
                                    }

                                    return (
                                        <div style={contentStyle} key={index}>
                                            {el.userInput}
                                        </div>
                                    )
                                })}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default SignatureDisplay;
