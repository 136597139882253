import React, { Component } from 'react'
import AppInputList from '../leftContainer/AppInputList'
import {  Collapse, CardBody } from 'reactstrap';

export default class AppCollapseNavBar extends Component {
    constructor(props){
        super(props)
        
        this.state={
            openTab:[{name:"content",isOpen:true,isActive:"active"},{name:"social",isOpen:false,isActive:""},{name:"images",isOpen:false,isActive:""},{name:"style",isOpen:false,isActive:""}]
        }

    }

    toggleTab(id){
        let newOpenTab=[];
        this.state.openTab.forEach(el=>{
            if(el.name===id) newOpenTab.push({name:el.name,isOpen:true,isActive:"active"});
            else newOpenTab.push({name:el.name,isOpen:false,isActive:""});
        })
        this.setState({openTab:newOpenTab});
    }
    render() {
        const whosOpen = this.state.openTab.find(el=>el.isOpen===true);
        const content=this.state.openTab[0].isActive
        const social=this.state.openTab[1].isActive
        const images=this.state.openTab[2].isActive
        const style=this.state.openTab[3].isActive
        
        return (
            <div className="menu-with-customisation-column">
                <div className="fixed-column">
                    <div>
                        <div className="text-center">
                            <div onClick={() => this.toggleTab("content")} className={`${content !== '' ? 'active-nav': ''} default-nav`}>
                                General
                            </div>

                            <div onClick={() => this.toggleTab("social")} className={`${social !== '' ? 'active-nav': ''} default-nav`}>
                                Links
                            </div>

                            <div onClick={() => this.toggleTab("images")} className={`${images !== '' ? 'active-nav': ''} default-nav`}>
                                Images
                            </div>

                            <div onClick={() => this.toggleTab("style")} className={`${style !== '' ? 'active-nav': ''} default-nav`}>
                                Design
                            </div>
                        </div>
                    </div>
                  </div>
                
                <Collapse isOpen={whosOpen.isOpen}>
                  <CardBody className="py-3">
                    <AppInputList imgChange={this.props.imgChange} change={this.props.change} listName={whosOpen.name} getList={this.props.getList}/>
                  </CardBody>
                </Collapse>
            </div>
        )
    }
}