import React, { useEffect, useState } from "react";
import "./Notification.css";

const Notification = ({ message, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (onClose) {
        setTimeout(onClose, 500); // Wait for fade-out before calling onClose
      }
    }, 2500); // Display notification for 2 seconds before fading out

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={`notification ${visible ? "visible" : "hidden"}`}>
      <span className="icon">✔</span>
      <span className="message">{message}</span>
    </div>
  );
};

export default Notification;
