import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './ModalDisplay.css';

const guideImg = require('../../img/guide-page-1.png');

const ModalDisplay = (props) =>  {
    return(
        <div>
            <Modal size='lg' isOpen={props.isOpen} toggle={props.toggle}>
                <ModalHeader toggle={props.toggle} className="p-2 m-0 ml-2" >
                    <img width='24px' height='24px' alt="modal-logo" src={require('./../../img/logo.png')}/>
                </ModalHeader>
                <ModalBody className="mx-auto">
                    <div className='step-container'>
                        <h2 className='step-content'>
                            <span aria-label='arrow' role="img" className="scaling-text">➡️</span> <a href="https://mail.google.com/" target='_blank' rel="noopener noreferrer">Open Gmail</a> and click the icon to paste your signature into the email.
                        </h2>
                        <img src={guideImg} alt='paste your signature' className='img-container'/>
                    </div>
                </ModalBody>
            </Modal>                   
        </div>
    )
}    

export default ModalDisplay;

