import React from 'react';
import { FormGroup, Label, Input,CustomInput } from 'reactstrap';


const AppInput= props =>(
    <div>
        {
        props.type==="file"?
            <FormGroup >
                <Label className="ml-1" for={props.id}>{props.id} Image</Label>
                <CustomInput
                    type="file"
                    id={props.id}
                    name="customFile"
                    onChange={(event)=>props.imgChange(event,props.id,props.listName)}
                    label="Pick a file!"
                />
            </FormGroup>
            :
            <FormGroup className="mr-2">
                <Label className="ml-1" for={props.id}>{props.id}</Label>
                <Input
                    placeholder={props.listName==="social"?`${props.id} URL`:null}
                    type={props.type}
                    value={props.userInput}
                    min={props.min}
                    max={props.max}
                    id={props.id}
                    onChange={(event)=>props.change(event,props.id,props.listName)}
                    className={props.type==="range"?"custom-range":"input-default"}
                />
            </FormGroup>
        }
    </div>
)

export default AppInput;