import { useEffect } from "react";

const UmamiScriptComponent = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://cloud.umami.is/script.js";
    script.defer = true;
    script.setAttribute("data-website-id", "ea471a6f-7769-4e63-9b39-7d82223a5d77"); // Add custom attribute

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null
};

export default UmamiScriptComponent;
